import React from "react"

import { Block } from "baseui/block"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import BannerPlain from "../containers/BannerPlainSection"
import WhatIsCryptoNewsAPI from "../containers/CryptoNewsAPI/WhatIsCryptoNewsAPI"
import WhyCryptoNewsAPI from "../containers/CryptoNewsAPI/WhyCryptoNewsAPI"
import FeaturesCryptoNewsAPI from "../containers/CryptoNewsAPI/FeaturesCryptoNewsAPI"
import BlogCaseStudy from "../containers/CryptoNewsAPI/BlogCaseStudy"
import TrySection from "../containers/CryptoNewsAPI/TrySection"
import { Newsletter } from "../containers/Newsletter"
import BannerBG from "../images/banner-blocklynx.png"

export default () => {
  return (
    <Layout>
      <SEOPage
        title="Cryptocurrency News API"
        description="Monitor crypto news and risk easily with sentiment analysis scoring"
      />
      <BannerPlain
        title="Cryptocurrency News API"
        subTitle="News and risk API from Coindesk, Cointelegraph and more, with automatic risk scoring using our sentiment analysis module"
        background={BannerBG}
        backgroundSize="cover"
        backgroundColor="#0C6B58"
      />
      <WhatIsCryptoNewsAPI />
      <WhyCryptoNewsAPI />
      <TrySection />
      <FeaturesCryptoNewsAPI />
      <BlogCaseStudy />
      <Block marginBottom="150px" />
      <Newsletter ButtonText="Download the Infographic Report" />
    </Layout>
  )
}
