import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { Select } from "baseui/select"
import { FormControl } from "baseui/form-control"
import { ResponsiveLine } from "@nivo/line"
import { Title, Description } from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const CRYPTONEWS_API = "https://asia-northeast1-cylynx-dev-badc.cloudfunctions.net/cryptoNewsProxy/"
const now = new Date()
const startDate = new Date()
startDate.setTime(startDate.getTime() - 24 * 60 * 60 * 1000 * 30) //30 days

const processResults = (res, entity) => {
  const cleanRes = res.data.map(arr => {
    const id = `${arr[0]}-${entity}`
    const data = arr[1].map((score, i) => {
      const nextDate = new Date()
      nextDate.setTime(nextDate.getTime() - 24 * 60 * 60 * 1000 * (30 - i))
      return {
        x: nextDate.toISOString().substring(0, 10),
        y: score[0],
      }
    })
    return {
      id,
      data,
    }
  })
  return cleanRes
}

const TrySection = () => {
  const Data = useStaticQuery(graphql`
    query {
      imgixImage(url: "/uploads/tm_flowchart.jpg") {
        fluid(maxWidth: 1600) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)
  const [entity, setEntity] = React.useState([
    { label: "Binance", id: "binance" },
  ])
  const [source, setSource] = React.useState([
    { label: "Cointelegraph", id: "cointelegraph" },
  ])

  const [data, setData] = React.useState([])

  React.useEffect(() => {
    fetch(
      `${CRYPTONEWS_API}getScore?start=${startDate
        .toISOString()
        .substring(0, 10)}&end=${now
        .toISOString()
        .substring(0, 10)}&limit=5&source[]=${source[0].id}&entity[]=${
        entity[0].id
      }`
    )
      .then(res => res.json())
      .then(res => setData(processResults(res, entity[0].id)))
  }, [entity, source])

  return (
    <Block paddingTop="60px" paddingBottom="60px" id="tm-feature">
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={24}>
          <Cell span={[12]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Title
                style={{
                  textAlign: "center",
                }}
              >
                Try it out
              </Title>
              <Description
                style={{
                  textAlign: "center",
                }}
                paddingBottom="30px"
              >
                Select one of the entities below to plot its risk score over time
              </Description>
            </Block>
          </Cell>
          <Cell span={[12]}>
            <FlushedGrid >
              <Cell span={[12, 4, 4]}>
                <FormControl label="Select exchange or coin">
                  <Select
                    options={[
                      { label: "Binance", id: "binance" },
                      { label: "Bittrex", id: "bittrex" },
                      { label: "Gemini", id: "Gemini" },
                      { label: "Okex", id: "okex" },
                      { label: "Bitcoin", id: "bitcoin" },
                      { label: "Ethereum", id: "ethereum" },
                      { label: "Monero", id: "monero" },
                      { label: "Tether", id: "tether" },
                    ]}
                    value={entity}
                    placeholder="Select entity"
                    onChange={params => setEntity(params.value)}
                  />
                </FormControl>
                <FormControl label="Select news source">
                  <Select
                    options={[
                      { label: "Coindesk", id: "coindesk" },
                      { label: "Cointelegraph", id: "cointelegraph" },
                      { label: "Cryptoslate", id: "cryptoslate" },
                    ]}
                    value={source}
                    placeholder="Select entity"
                    onChange={params => setSource(params.value)}
                  />
                </FormControl>
              </Cell>
              <Cell span={[12, 8, 8]}>
                <Block height="400px">
                  <ResponsiveLine
                    data={data}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{
                      type: "time",
                      format: "%Y-%m-%d",
                      useUTC: false,
                      precision: "day",
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                      type: "linear",
                      min: "0",
                      max: "100",
                      stacked: false,
                      reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisBottom={{
                      format: "%b %d",
                      tickValues: "every 2 days",
                    }}
                    axisLeft={{
                      orient: "left",
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "risk score",
                      legendOffset: -40,
                      legendPosition: "middle",
                    }}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                      {
                          anchor: 'top-right',
                          direction: 'row',
                          justify: false,
                          translateY: -20,
                          itemDirection: 'left-to-right',
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: 'circle',
                      }
                  ]}
                  />
                </Block>
              </Cell>
            </FlushedGrid>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default TrySection
