import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import Image from "../../components/Image"
import {
  Title,
  SubTitle,
  Description,
  ItemTitle,
  ItemDescription,
  Code,
} from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const FeaturesCryptoNewsAPI = () => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        NEWSAPI_FEATURE {
          title
          description
          icon {
            publicURL
          }
        }
      }
      imgixImage(url: "/uploads/blocklynx-screen.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block
      paddingTop="100px"
      paddingBottom="100px"
      id="discover-transaction-monitoring"
    >
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell order={[2, 2, 1]} span={[12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Title>API Endpoints</Title>
              <Description paddingBottom="40px">
                Get information on crypto entities or from particular sources
              </Description>
              <FlushedGrid gridGaps={[18, 32]}>
                {Data.cryptoJson.NEWSAPI_FEATURE.map(item => (
                  <Cell key={`feature-${item.title}`} span={6}>
                    <Fade up>
                      <Block display="flex">
                        <Image
                          src={item.icon.publicURL}
                          alt={item.title}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginBottom: "20px",
                            borderWidth: "2px",
                            borderColor: "rgb(233, 227, 254)",
                            borderRadius: "10px",
                            backgroundColor: "rgb(243, 242, 250)",
                            padding: "6px 8px",
                          }}
                        />
                        <Block marginLeft="15px">
                          <ItemTitle>{item.title}</ItemTitle>
                          <ItemDescription>{item.description}</ItemDescription>
                        </Block>
                      </Block>
                    </Fade>
                  </Cell>
                ))}
              </FlushedGrid>
            </Block>
          </Cell>
          <Cell order={[1, 1, 2]} span={[12, 6]}>
            <Block
              display="flex"
              alignItems="center"
              height="100%"
              maxWidth="600px"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Code language="js">
                {`// topArticles?start=2021-07-20&end=2021-07-29&limit=5&source[]=coindesk&entity[]=Binance
  {
      "data": [
        [
            8849,
            "What Is Going On With Bitcoin SV?",
            "Following an attack on the network, more exchanges reportedly suspended trading of the BCH spin-off and Binance is shutting down its BSV mining pool. ",
            "2021-07-23",
            "https://www.coindesk.com/what-is-going-on-with-bitcoin-sv",
            51.059587710059986,
            "coindesk"
        ],
        [
            8652,
            "Binance Pool to Halt BSV Mining Services at End of July",
            "Clients using BSV mining will need to manually switch to BTC mining or Binance Smart Pool.",
            "2021-07-20",
            "https://www.coindesk.com/binance-pool-to-halt-bsv-mining-services-at-end-july",
            1.073324100187648,
            "coindesk"
        ],
    ],
    "limit": "2"
}`}
              </Code>
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default FeaturesCryptoNewsAPI
